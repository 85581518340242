const MENU_ITEMS = [
    { key: 'sms', icon: 'uil-envelope-alt', label: 'СМС Рассылки', url: '/sms' },
    { key: 'bases', icon: 'uil-database', label: 'Базы для рассылок', url: '/bases' },
    { key: 'agents', icon: 'uil-user', label: 'Агенты', url: '/agents' },
    { key: 'flowbases', icon: 'uil-arrows-up-right', label: 'Потоковые базы', url: '/flowbases' },
    { key: 'blacklists', icon: 'uil-minus-circle', label: 'Стоп-лист', url: '/blacklists' },
    { key: 'statistics', icon: 'uil-chart-line', label: 'Статистика', url: '/stats' },
];
const ADMIN_MENU_ITEMS = [
    { key: 'sms', icon: 'uil-envelope-alt', label: 'СМС Рассылки', url: '/sms' },
    { key: 'bases', icon: 'uil-database', label: 'Базы для рассылок', url: '/bases' },
    { key: 'agents', icon: 'uil-user', label: 'Агенты', url: '/agents' },
    { key: 'flowbases', icon: 'uil-arrows-up-right', label: 'Потоковые базы', url: '/flowbases' },
    { key: 'blacklists', icon: 'uil-minus-circle', label: 'Стоп-лист', url: '/blacklists' },
    { key: 'statistics', icon: 'uil-chart-line', label: 'Статистика', url: '/stats' },
    { key: 'admin', icon: 'uil-sitemap', label: 'Админ панель', url: '/admin' },
];

export { MENU_ITEMS, ADMIN_MENU_ITEMS };
