// @flow
import {StatsActionTypes} from './constants';

const INIT_STATE = {
    stats: [],
    loading: false,
    totals: {user: "Итого"},
    error: null
};

type StatsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Stats = (state: State = INIT_STATE, action: StatsAction): any => {
    switch (action.type) {
        case StatsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case StatsActionTypes.GET_STATS: {
                    return {
                        ...state,
                        pageCount: action.payload?.data?.pageCount,
                        stats: action.payload?.data?.statsList,
                        totals: action.payload?.data?.totals,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case StatsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case StatsActionTypes.GET_STATS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case StatsActionTypes.GET_STATS:
            return {
                ...state,
                stats: INIT_STATE.stats,
                totals: INIT_STATE.totals,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default Stats;
