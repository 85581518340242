// @flow
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {StatsActionTypes} from './constants';
import {getStats as getStatsApi,} from '../../helpers/';
import {smsApiResponseError, smsApiResponseSuccess} from './actions';

function* getStats(action) {
    try {
        const response = yield call(getStatsApi, action.payload);
        const { statsList, totalCount, totalValues } = response.data.stats;
        statsList.forEach(statItem => {
            statItem._id = statItem._id?.sms
        })
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        const totals = {
            user: "Итого", ...totalValues
            // summ: '1000',
            // summ_all: '100000',
            // count_all: '3440'
        }
        yield put(smsApiResponseSuccess(StatsActionTypes.GET_STATS, { statsList, pageCount, totals }));
    } catch (error) {
        yield put(smsApiResponseError(StatsActionTypes.GET_STATS, error));
    }
}

export function* watchGetStats(): any {
    yield takeEvery(StatsActionTypes.GET_STATS, getStats);
}

function* statsSaga(): any {
    yield all(
        [fork(watchGetStats)]
    );
}

export default statsSaga;
