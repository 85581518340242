import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Root from './Root';
import PrivateRoute from './PrivateRoute';

import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';
import {ROLES} from '../constants';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

// pages
const ErrorPageNotFound = React.lazy(() => import('../pages/other/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/other/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/other/error/ServerError'));
const Bases = React.lazy(() => import('../pages/smska/bases/Bases'));
const BasesLoadingForm = React.lazy(() => import('../pages/smska/bases/BasesUploadingForm'));
const Agents = React.lazy(() => import('../pages/smska/agents/Agents'));
const AddAgentForm = React.lazy(() => import('../pages/smska/agents/AddAgentForm'));
const Flowbases = React.lazy(() => import('../pages/smska/flowbases/Flowbases'));
const CreateFlowbaseForm = React.lazy(() => import('../pages/smska/flowbases/CreateFlowbaseForm'));
const Blacklists = React.lazy(() => import('../pages/smska/blacklists/Blacklists'));
const BlacklistsCreateForm = React.lazy(() => import('../pages/smska/blacklists/BlacklistsCreateForm'));
const Sms = React.lazy(() => import('../pages/smska/sms/Sms'));
const NewSmsPage = React.lazy(() => import('../pages/smska/sms/NewSmsPage'));
const AdminPage = React.lazy(() => import('../pages/smska/admin_page'));
const NewUser = React.lazy(() => import('../pages/smska/admin_page/NewUser'));
const Statistics = React.lazy(() => import('../pages/smska/statistics/Statistics'));

// - other
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Starter = React.lazy(() => import('../pages/other/Starter'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={[ROLES.admin, ROLES.user]} component={Layout} />,
            children: [
                {
                    path: 'sms',
                    element: <LoadComponent component={Sms} />,
                },
                {
                    path: 'bases',
                    element: <LoadComponent component={Bases} />,
                },
                {
                    path: 'bases/create',
                    element: <LoadComponent component={BasesLoadingForm} />,
                },
                {
                    path: 'agents',
                    element: <LoadComponent component={Agents} />,
                },
                {
                    path: 'agents/create',
                    element: <LoadComponent component={AddAgentForm} />,
                },
                {
                    path: 'flowbases',
                    element: <LoadComponent component={Flowbases} />,
                },
                {
                    path: 'flowbases/create',
                    element: <LoadComponent component={CreateFlowbaseForm} />,
                },
                {
                    path: 'blacklists',
                    element: <LoadComponent component={Blacklists} />,
                },
                {
                    path: 'blacklists/create',
                    element: <LoadComponent component={BlacklistsCreateForm} />,
                },
                {
                    path: 'sms/create',
                    element: <LoadComponent component={NewSmsPage} />,
                },
                {
                    path: 'stats',
                    element: <LoadComponent component={Statistics} />,
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                    ],
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={ROLES.admin} component={Layout} />,
            children: [
                {
                    path: 'admin',
                    element: <LoadComponent component={AdminPage} />,
                },
                {
                    path: 'admin/new-user',
                    element: <LoadComponent component={NewUser} />,
                },
            ],
        },
    ]);
};

export { AllRoutes };
